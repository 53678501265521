#login-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 2rem;
    height: 400px;
    width: 300px;
    max-width: 95vw;
    border-radius: 10px;
    margin-top: 4rem;
}

.login-input{
    margin: 10px auto;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border: 1px solid grey;
    padding-left: 10px;
}

