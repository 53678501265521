.admin-btn{
  height: 40px;
  border-radius: 20px;
  width: 150px;
  border: unset;
  margin: 1rem 0;
  font-weight: bold;
  background-color: #1e1e1e;
  color: white;
  transition: 0.5s;
}

.admin-btn:hover{
  cursor: pointer;
  background-color: #3d3d3d;
}

nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  flex-wrap: wrap;
}

nav a {
  margin: 0 1rem;
}

table{
  width: 1200px;
  max-width: 90vw;
  margin: 1rem auto;
  border-collapse: collapse;
}

td, th{
  text-align: left;
  height: 40px;
  padding: 5px;
  margin: 0;
}

 tr:nth-child(even){
  background-color: #dfdfdf;
}

.viewID-btn{
  margin-left: 1rem;
  height: 40px;
  border-radius: 20px;
  width: 150px;
  border: unset;
}

#id-modal{
  position: absolute;
  width: 350px;
  top: 40%;
  left: calc(50vw - 175px);
  background-color: white;
  border: 2px solid grey;
  border-radius: 10px;
  padding: 1rem;
}

nav{
  background-color: #272838;
}

nav a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}