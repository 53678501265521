#new-question{
    display: flex;
    flex-direction: column;
    width: 600px;
    max-width: 95vw;
    margin: 1rem auto;
}

.course-container{
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem 0;
}

.course-input{
    height: 50px;
    width: 500px;
    border-radius: 10px;
    border: 1px solid grey;
}   